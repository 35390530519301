import * as echarts from 'echarts'

export default {
  props: {
    data: { type: Object, default: () => ({name: '', types: [], series: []}) }
  },
  data () {
    return {
      $echarts: null,
      active: {
        seriesName: null,
        name: null,
        dataIndex: null,
        seriesIndex: null
      }
    }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.render()
      }
    }
  },
  methods: {
    render () {
      if (!this.$echarts) {
        this.$echarts = echarts.init(this.$el)
        this.$echarts.on('click', 'series', this.onClick)
      }

      this.$echarts.setOption(this.getOptions(), {
        notMerge: true
      })
    },
    unselect () {
      if (this.active.seriesName && this.active.name) {
        this.$echarts.dispatchAction({
          type: 'unselect',
          dataIndex: this.active.dataIndex
        })
      }
    },
    select ({ seriesName, name, dataIndex, seriesIndex }) {
      if (this.active.dataIndex !== dataIndex) {
        this.$echarts.dispatchAction({
          type: 'select',
          dataIndex: dataIndex
        })
        this.active.name = name
        this.active.dataIndex = dataIndex
        this.active.seriesName = seriesName
        this.active.seriesIndex = seriesIndex
      } else {
        this.active.name = null
        this.active.dataIndex = null
        this.active.seriesName = null
        this.active.seriesIndex = null
      }
    },
    onClick ({ seriesName, name, dataIndex, seriesIndex }) {
      this.unselect()
      this.select({ seriesName, name, dataIndex, seriesIndex })
      this.$emit('active', { seriesName, name, dataIndex, seriesIndex })
    }
  },
  destroyed () {
    if (this.$echarts) {
      this.$echarts.off('click', 'series', this.onClick)
    }
  },
  mounted () {
    this.render()
  }
}