import * as echarts from 'echarts'
export default {
  props: {
    name: { type: String, default: '' },
    data: { type: Array, default: () => ([]) }
  },
  data () {
    return {
      $echarts: null,
      active: {
        seriesName: null,
        name: null,
        dataIndex: null,
        seriesIndex: null
      }
    }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.render()
      }
    },
    name: {
      handler () {
        this.render()
      }
    }
  },
  methods: {
    getOptions () {
      return {
        title: {
          text: '医护比' + this.name
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '5%',
          right: '5%',
          orient: 'vertical'
        },
        series: [
          {
            name: '医护比',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 0,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '20',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: this.data
          }
        ]
      }
    },
    render () {
      if (!this.$echarts) {
        this.$echarts = echarts.init(this.$el)
        this.$echarts.on('click', 'series', this.onClick)
      }

      this.$echarts.setOption(this.getOptions())
    },
    onClick ({ seriesName, name, dataIndex, seriesIndex }) {
      if (this.active.seriesName && this.active.name) {
        this.$echarts.dispatchAction({
          type: 'downplay',
          name: this.active.name,
          seriesName: this.active.seriesName,
          seriesIndex: this.active.seriesIndex,
          dataIndex: this.active.dataIndex
        })
      }

      if (this.active.dataIndex !== dataIndex) {
        this.$echarts.dispatchAction({
          type: 'highlight',
          name: name,
          seriesName: seriesName,
          seriesIndex: seriesIndex,
          dataIndex: dataIndex
        })
        this.active.name = name
        this.active.dataIndex = dataIndex
        this.active.seriesName = seriesName
        this.active.seriesIndex = seriesIndex
      } else {
        this.active.name = null
        this.active.dataIndex = null
        this.active.seriesName = null
        this.active.seriesIndex = null
      }

      this.$emit('active', this.active)
    }
  },
  destroyed () {
    if (this.$echarts) {
      this.$echarts.off('click', 'series', this.onClick)
    }
  },
  mounted () {
    this.render()
  }
}