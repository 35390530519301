<style scoped></style>

<template>
  <div></div>
</template>

<script>
import Mixins from '../mixins/bar'

export default {
  mixins: [Mixins],
  methods: {
    getOptions () {
      return {
        title: {
          text: '职称级别年龄分布' + this.data.name
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          top: 25,
          right: '5%'
        },
        grid: {
          left: '3%',
          right: '3%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          name: '职称级别',
          nameLocation: 'start',
          nameRotate: '-270',
          // nameRotate: '-90',
          data: this.data.types
        },
        yAxis: {
          type: 'value'
        },
        series: this.data.series
      }
    }
  }
}
</script>