<style scoped lang="less">
  @import './layout.less';
  .search-b {
    background: #fff;
    padding: 5px 10px;
  }
</style>

<template>
  <div class="analysis-medical-page layout">
    <div class="search-b">
      <span style="margin-right: 1rem;">人员类别</span>
      <fm-select @change="loadData" absolute filterable v-model="jobType">
        <fm-option v-for="item in workerJobTypes" :key="item.key" :value="item.key" :label="item.label"></fm-option>
      </fm-select>
    </div>
    <div class="top">
      <Rate :key="loadkey + 'rate'" class="left" @active="v => setActive('rate', v)" :data="rateData" :name="rateName" />
      <Bar :key="loadkey + 'bar'" class="right" @active="v => setActive('bar', v)" :data="barData" />
    </div>
    <details-table :data-list="dataList" :key="loadkey + 'table'" class="bottom" />
    <div class="count">共{{dataList.length}}条数据</div>
  </div>
</template>

<script>
import Bar from './age/bar.vue'
import Rate from './age/rate.vue'
import DetailsTable from './age/details.vue'
import { analysisRequest } from '../../api'

const ageTypes = [
  { name: '25岁以下', fn: (age) => age > 0 && age < 25 },
  { name: '25-35岁', fn: (age) => age >= 25 && age < 35 },
  { name: '35-45岁', fn: (age) => age >= 35 && age < 45 },
  { name: '45-55岁', fn: (age) => age >= 45 && age < 55 },
  { name: '55岁以上', fn: (age) => age >= 55 },
  { name: '无年龄', fn: (age) => !age }
]

let timer = null

export default {
  components: { Bar, Rate, DetailsTable },
  data () {
    return {
      dataList: [],
      baseDataList: [],
      rateData: [],
      rateName: '',
      barData: {
        types: [],
        series: [],
        name: ''
      },
      loading: {
        load: false
      },
      loadkey: 0,
      activeData: {},
      jobType: 'all'
    }
  },
  computed: {
    workerJobTypes () {
      return [{key: 'all', label: '全部'}].concat(this.$store.getters.selectDatas['worker_job_type'])
    }
  },
  methods: {
    resize () {
      clearTimeout(timer)
      timer = setTimeout(() => {
        this.loadkey++
      }, 500)
    },
    setActive (type, data) {
      this.activeData[type] = JSON.parse(JSON.stringify(data))
      let dataList = this.baseDataList

      if (this.activeData.rate && this.activeData.rate.name) {
        const age = ageTypes.find(v => v.name === this.activeData.rate.name)
        dataList = dataList.filter(v => !age || age.fn(Number(v.age)))
      } 
      
      if (this.activeData.bar && this.activeData.bar.name) {
        dataList = dataList.filter(v => v.level === this.activeData.bar.name)
      }
      
      this.dataList = dataList
      this.generateBarData(this.activeData.rate ? this.activeData.rate.name : null)
      this.generateRateData(this.activeData.bar ? this.activeData.bar.name : null)
    },
    generateBarData (nameSet) {
      const levelType = Array.from(new Set(this.baseDataList.map(v => v.level)))
      const ages = ageTypes.filter(v => !nameSet || nameSet === v.name)
      this.barData = {
        name: nameSet ? (' - ' + nameSet) : '',
        types: levelType,
        series: ages.map(({ name, fn }) => {
          return {
            name: name,
            type: 'bar',
            stack: 'num',
            label: {
              show: true
            },
            selectedMode: 'single',
            itemStyle: {
              opacity: 0.8
            },
            emphasis: {
              itemStyle: {
                opacity: 1
              },
              label: {
                fontWeight: 'bolder',
                fontSize: 16
              }
            },
            barMaxWidth: 40,
            data: levelType.map(level => {
              return this.baseDataList.filter(v => v.level === level && fn(Number(v.age))).length
            })
          }
        })
      }
    },
    generateRateData (nameSet) {
      this.rateName = nameSet ? (' - ' + nameSet) : ''
      this.rateData = ageTypes.map(({ name, fn }) => {
        return {
          name: name,
          value: this.baseDataList.filter(v => (fn(Number(v.age)) && (!nameSet || v.level === nameSet))).length
        }
      })
    },
    async loadData () {
      this.loading.load = true
      let dataList = []
      let parm = {
        jobType: this.jobType,
        isNotLeave: 1
      }
      if (this.$authFunsProxy.get) {
        dataList = await analysisRequest.certificateLevel(parm)
      } else if (this.$authFunsProxy.getMyOrgContainChild) {
        dataList = analysisRequest.certificateLevelMyOrgContainChild(parm)
      } else if (this.$authFunsProxy.getMyOrg) {
        dataList = await analysisRequest.certificateLevelMyOrg(parm)
      }
      this.loading.load = false

      dataList.forEach(v => {
        v.level = v.level || '无职称'
        v.yh = v.yh || '其他'
      })

      this.dataList = this.baseDataList = dataList
      this.generateBarData()
      this.generateRateData()
    }
  },
  mounted () {
    this.loadData()
    window.addEventListener('resize', this.resize)
  },
  destroyed () {
    window.removeEventListener('resize', this.resize)
  }
}
</script>
